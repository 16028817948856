<template>
  <div class="container wrapper">
    <h1 class="pageHeader my-12">Push Notifications</h1>
    <div class="d-flex">
      <div class="align-self-center">Allow Notifications</div>
      <div class="ml-auto">
        <v-switch
          v-model="push"
          inset
          dark
          color="primary"
          @click="onChange()"
        ></v-switch>
      </div>
    </div>
  </div>
</template>

<script>
  import { PROFILE } from '@/api';
  import { AUTH_ME } from '@/store/auth.module';

  export default {
    name: 'DisplayInformations',
    data: () => ({
      push: true
    }),
    computed: {
      me() {
        return this.$store.state.auth.me.data;
      }
    },
    watch: {
      me(val) {
        if (val.push === false) {
          this.push = false;
        }
      }
    },
    methods: {
      async onChange() {
        this.$startLoading();

        try {
          await PROFILE.pushNotificationSetting(this.push ? '1' : '0');
          this.getMe();
        } catch (e) {
          this.openAppDialogInfo(
            'error',
            e.error_message || 'Request Failed',
            '',
            [
              {
                text: 'OK',
                action: () => {
                  this.closeAppDialogInfo();
                }
              }
            ]
          );
        }
        this.$stopLoading();
      },
      getMe() {
        this.$store.dispatch(AUTH_ME);
      }
    },
    mounted() {
      if (this.me?.push === false) {
        this.push = false;
      }
    }
  };
</script>

<style scoped lang="scss">
  .contentContainer {
    height: 450px;
  }
</style>
